import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addEmployee(role_id, role_type, group_id, first_name, last_name, mobile_no, email, employee_id, time_approver_id, time_reviwer_id, password, status, emp_user_type, emp_rate, regular_hour,is_supervisor) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        role_id,
        role_type,
        group_id,
        first_name,
        last_name,
        mobile_no,
        email,
        employee_id,
        time_approver_id,
        time_reviwer_id,
        password,
        status,
        emp_user_type,
        emp_rate,
        regular_hour,
        is_supervisor
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_EMPLOYEE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateEmployee(emp_id, role_id, role_type, group_id, first_name, last_name, mobile_no, email, time_approver_id, time_reviwer_id, password, status, emp_user_type, emp_rate, regular_hour,is_supervisor) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        emp_id,
        role_id,
        role_type,
        group_id,
        first_name,
        last_name,
        mobile_no,
        email,
        time_approver_id,
        time_reviwer_id,
        password,
        status,
        emp_user_type,
        emp_rate,
        regular_hour,
        is_supervisor
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_EMPLOYEE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getEmployee(searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_EMPLOYEE_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateEmployeeStatus(emp_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        emp_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_EMPLOYEE_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeEmployee(emp_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        emp_id,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_EMPLOYEE_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeMultipleEmployee(userIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        userIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_MULTIPLE_EMPLOYEE_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getApprover() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_APPROVER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getReviewer() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_REVIEWER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getTimeKeeper() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_KEEPER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getKeeperTeam(group_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        group_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_KEEPER_TEAM_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}


export function downloadEmployeeRecord(userIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        userIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.DOWNLOAD_EMPLOYEE_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function verifyEmployeeId(emp_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        emp_id,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.VERIFY_EMPLOYEE_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function verifyEmail(email) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let user_id = JSON.parse(getData)._id;

    const postData = {
        user_id,
        email,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.VERIFY_USERS_EMAIL_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}