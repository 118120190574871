import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
	Row,
	Col,
	Card,
	Table,
	Modal,
	Button,
	Accordion,
} from "react-bootstrap";
import Moment from 'react-moment';
import swal from "sweetalert";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { loadingRoleAction, addPrivilegeData, getPrivilegeData, getActiveRoleList, getRolePermissionsData } from '../../../store/actions/RoleActions';
import { updateClientsData, loadingClientsAction } from '../../../store/actions/ClientsActions';
import { loadingEmployeeAction, addEmployeeData, getEmployeeList, updateEmployeeStatusData, UpdateEmployeeData, removeEmployeeData, getApproverList, getReviewerList, downloadEmployeeData, removeMultipleEmployeeData } from '../../../store/actions/EmployeeActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { validateEmail, validatePassword } from '../../../utility/Utility';
import { loadingTeamAction, getTeamList } from '../../../store/actions/TeamActions';
import { getCountryList, countryLoadingAction } from '../../../store/actions/CountryAction';
import { verifyEmployeeId, verifyEmail } from '../../../services/EmployeeService';


const ManageUsers = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	let errorsObj = { role: '', group: '', fName: '', lName: '', mobile: '', email: '', employeeId: '', password: '', conPassword: '', approver: '', reviewer: '', status: '', userType: '', rate: '', hours: '', privilege: '' };

	const [errors, setErrors] = useState(errorsObj);

	const [roleName, setRoleName] = useState('');
	const [groupName, setGroupName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [mobile, setMobile] = useState('');
	const [email, setEmail] = useState('');
	const [employeeId, setEmployeeId] = useState('');
	const [password, setPassword] = useState('');
	const [conPassword, setConPassword] = useState('');
	const [approver, setApprover] = useState('');
	const [reviewer, setReviewer] = useState('');
	const [userType, setUserType] = useState('');
	const [rate, setRate] = useState('');
	const [hours, setHours] = useState('');
	const [empId, setEmpId] = useState('');
	const [permission, setPermission] = useState([]);
	const [defaultPermission, setDefaultPermission] = useState([1]);
	const [quickLink, setQuickLink] = useState([]);
	const [isSupervisor, setIsSupervisor] = useState(false);

	const [addModal, setAddModal] = useState(false);
	const [loadingStatus, setLoadingStatus] = useState(1);
	const [action, setAction] = useState(1);
	const [status, setStatus] = useState('');
	const [pws, setPws] = useState(false);
	const [conPws, setConPws] = useState(false);
	const [accOne, setAccOne] = useState(false);
	const [accTwo, setAccTwo] = useState(false);
	const [accThree, setAccThree] = useState(false);

	const [isDisabled, setIsDisabled] = useState(true);
	const [checkArray, setCheckArray] = useState([]);
	const [validEmail, setValidEmail] = useState(false);
	const [validEmpid, setValidEmpid] = useState(false);

	const chackbox = document.querySelectorAll(".checkbox-select input");
	const motherChackBox = document.querySelector("#checkAll");
	const chackbox2 = document.querySelectorAll(".form-check-label input");

	//filter
	const [searchText, setSearchText] = useState('');
	const [sordBy, setSordBy] = useState('');
	const [sort, setSort] = useState(null);
	const [pageNo, setPageNo] = useState(1);
	const [perPage, setPerPage] = useState(10);

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getEmployeeList('', '', '', 1, perPage, props.history));
		dispatch(getTeamList(props.history));
		dispatch(getActiveRoleList(props.companyId, props.history));
		dispatch(getCountryList(props.history));
		//dispatch(getRolePermissionsData(props.history));
	}, []);

	function onSubmit(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		if (roleName === '') {
			errorObj.role = 'Role name is Required';
			setAccOne(true);
			error = true;
		}

		if (firstName === '') {
			errorObj.fName = 'First name is Required';
			error = true;
		}
		if (lastName === '') {
			errorObj.lName = 'Last name is Required';
			error = true;
		}
		if (mobile === '') {
			errorObj.mobile = 'Mobile is Required';
			error = true;
		}
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		} else if (validateEmail(email) == true || validEmail) {
			errorObj.email = 'Invalid Email Id';
			error = true;
		}
		if (employeeId === '' && action == 1) {
			errorObj.employeeId = 'Employee id is Required';
			error = true;
		} else if (validEmpid) {
			errorObj.employeeId = 'Invalid Employee Id';
			error = true;
		}
		if (userType === '') {
			errorObj.userType = 'User type name is Required';
			error = true;
		}
		if (rate === '') {
			errorObj.rate = 'Par hour rate is Required';
			error = true;
		}
		if (hours === '') {
			errorObj.hours = 'Regular hour is Required';
			error = true;
		}
		if (password === '' && action == 1) {
			errorObj.password = 'Password is Required';
			error = true;
		} else if (validatePassword(password).result == false && action == 1) {
			errorObj.password = validatePassword(password).error;
			error = true;
		}
		if (conPassword === '' && action == 1) {
			errorObj.conPassword = 'Confirm Password is Required';
			setAccOne(true);
			error = true;
		} else if (password !== conPassword) {
			errorObj.conPassword = 'Password and confirm password does not match';
			error = true;
		}
		// if (quickLink.length == 0) {
		// 	errorObj.privilege = 'Privilege is Required';
		// 	error = true;
		// }
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(globalLoadingAction(true));
		if (action == 1) {
			dispatch(addEmployeeData(roleName.split('^')[0],
				roleName.split('^')[1],
				groupName,
				firstName,
				lastName,
				mobile,
				email,
				employeeId,
				approver ? approver : 0,
				reviewer ? reviewer : 0,
				password,
				status,
				userType,
				parseInt(rate),
				parseInt(hours),
				isSupervisor?1:0,
				perPage,
				props.history));
			setSearchText('');
			setSordBy('');
			setSort(null);
			setPageNo(1);
		} else {
			dispatch(UpdateEmployeeData(
				empId,
				roleName.split('^')[0],
				roleName.split('^')[1],
				groupName,
				firstName,
				lastName,
				mobile,
				email,
				approver ? approver : 0,
				reviewer ? reviewer : 0, '',
				status,
				userType,
				parseInt(rate),
				parseInt(hours),
				isSupervisor?1:0,
				searchText,
				sordBy,
				sort === null ? sort ? 'asc' : 'desc' : '',
				pageNo,
				perPage,
				props.history));
		}
		setRoleName('');
		setFirstName('');
		setLastName('');
		setMobile('');
		setEmail('');
		setEmployeeId('');
		setPassword('');
		setConPassword('');
		setApprover('');
		setReviewer('');
		setUserType('');
		setGroupName('');
		setStatus('');
		setIsSupervisor(false)
		setAddModal(false);
		setAccTwo(false);
		setAccOne(false);
	}

	const onAddHandler = () => {

		const errorObj = { ...errorsObj };
		errorObj.role = '';
		errorObj.fName = '';
		errorObj.lName = '';
		errorObj.mobile = '';
		errorObj.email = '';
		errorObj.employeeId = '';
		errorObj.password = '';
		errorObj.conPassword = '';
		errorObj.approver = '';
		errorObj.reviewer = '';
		errorObj.status = '';
		errorObj.userType = '';
		errorObj.rate = '';
		errorObj.hours = '';
		setErrors(errorObj);
		setAddModal(true);
		setAction(1);
		setRoleName('');
		setFirstName('');
		setLastName('');
		setMobile('');
		setEmail('');
		setUserType('');
		setRate('');
		setHours('');
		setEmployeeId('');
		setApprover('');
		setReviewer('');
		setPassword('');
		setConPassword('');
		setGroupName('');
		setStatus('');
		setIsSupervisor(false);
		setAccTwo(false);
		setAccOne(false);
		setIsDisabled(true);
		setCheckArray([]);
		chackbox.forEach((val, index) => {
			chackbox[index].checked = false;
		});
		motherChackBox.checked = false;
	}


	const callBack = (item, permissions) => {
		setDefaultPermission(permissions);
		const errorObj = { ...errorsObj };
		errorObj.role = '';
		errorObj.fName = '';
		errorObj.lName = '';
		errorObj.mobile = '';
		errorObj.email = '';
		errorObj.employeeId = '';
		errorObj.password = '';
		errorObj.conPassword = '';
		errorObj.approver = '';
		errorObj.reviewer = '';
		errorObj.status = '';
		errorObj.userType = '';
		errorObj.rate = '';
		errorObj.hours = '';
		setErrors(errorObj);
		setAddModal(true);
		setQuickLink(permissions);
		setAction(2);
		setRoleName(item.other_details[0].role_id + '^' + item.other_details[0].role_type);
		setGroupName(item.other_details[0].group_id);
		setFirstName(item.first_name);
		setLastName(item.last_name);
		setMobile(item.phone_no);
		setUserType(item.other_details[0].user_type_name);
		setRate(item.other_details[0].rate);
		setHours(item.other_details[0].regular_hour);
		setEmail(item.email);
		setEmpId(item._id);
		setStatus(item.status);
		setApprover(item.other_details[0].time_approver_id);
		setReviewer(item.other_details[0].time_reviwer_id);
		setIsSupervisor(item.other_details[0].is_supervisor==1?true:false);
		//setIsSupervisor(true);
		setAccTwo(false);
		setAccOne(false);
		setAccThree(false);
		setIsDisabled(true);
		setCheckArray([]);
		chackbox.forEach((val, index) => {
			chackbox[index].checked = false;
		});
		motherChackBox.checked = false;

	}

	const onEditHandler = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(getPrivilegeData(item._id, item, callBack, props.history));
		const errorObj = { ...errorsObj };
		errorObj.role = '';
		errorObj.fName = '';
		errorObj.lName = '';
		errorObj.mobile = '';
		errorObj.email = '';
		errorObj.employeeId = '';
		errorObj.password = '';
		errorObj.conPassword = '';
		errorObj.approver = '';
		errorObj.reviewer = '';
		errorObj.status = '';
		errorObj.userType = '';
		errorObj.rate = '';
		errorObj.hours = '';
		setErrors(errorObj);
		setAddModal(true);
		setAction(2);
		setRoleName(item.other_details[0].role_id + '^' + item.other_details[0].role_type);
		setGroupName(item.other_details[0].group_id);
		setFirstName(item.first_name);
		setLastName(item.last_name);
		setMobile(item.phone_no);
		setUserType(item.other_details[0].user_type_name);
		setRate(item.other_details[0].rate);
		setHours(item.other_details[0].regular_hour);
		setEmail(item.email);
		setEmpId(item._id);
		setStatus(item.status);
		setApprover(item.other_details[0].time_approver_id);
		setReviewer(item.other_details[0].time_reviwer_id);
		setAccTwo(false);
		setAccOne(false);
		setAccThree(false);
		setIsDisabled(true);
		setCheckArray([]);
		chackbox.forEach((val, index) => {
			chackbox[index].checked = false;
		});
		motherChackBox.checked = false;
	}

	const onCollapseHandler = () => {
		setAccOne(!accOne);
		setAccTwo(accOne && accTwo && accThree ? true : false);
		setAccThree(accOne && accTwo && accThree ? true : false);
	}

	const onCollapseHandler2 = () => {
		setAccTwo(!accTwo);
		setAccOne(accOne && accTwo ? true : false);
		setAccThree(accOne && accTwo && accThree ? true : false);
	}

	const onCollapseHandler3 = () => {
		setAccThree(!accThree);
		setAccOne(accOne && accTwo && accThree ? true : false);
		setAccTwo(accOne && accTwo && accThree ? true : false);
	}

	const onStatusHandler = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateEmployeeStatusData(item));
	}

	const onDeleteHandler = (id) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				if (props.employeeList.length == 1) {
					dispatch(removeEmployeeData(id, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage, props.history));
					setPageNo(pageNo - 1);
				} else {
					dispatch(removeEmployeeData(id, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage, props.history));
				}
			}
		})
	}

	const onMultipleRemoveHandler = () => {
		swal({
			title: "Are you sure?",
			text: checkArray.length > 1 ? "You want to remove items!" : "You want to remove item!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(globalLoadingAction(true));
				if (props.employeeList.length == checkArray.length) {
					dispatch(removeMultipleEmployeeData(checkArray, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage, props.history));
					setPageNo(pageNo - 1);
				} else {
					dispatch(removeMultipleEmployeeData(checkArray, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage, props.history));
				}
				setIsDisabled(true);
				setCheckArray([]);
				chackbox.forEach((val, index) => {
					chackbox[index].checked = false;
				});
				motherChackBox.checked = false;
			}
		})
	}

	const getApproverHandler = () => {
		setLoadingStatus(1);
		dispatch(loadingEmployeeAction(true, props.history));
		dispatch(getApproverList(props.history));
	}

	const getReviewerHandler = () => {
		setLoadingStatus(2);
		dispatch(loadingEmployeeAction(true, props.history));
		dispatch(getReviewerList(props.history));
	}

	const chackboxFun = (element, item) => {

		let array = [...checkArray];
		if (item === 0) {
			if (motherChackBox.checked == true) {
				props.employeeList.forEach((val, index) => {
					array.push(val._id);
					document.querySelector(`#check${index}`).checked = true;
				});
				setIsDisabled(false);
			} else {
				props.employeeList.forEach((val, index) => {
					array = [];
					document.querySelector(`#check${index}`).checked = false;
				});
				setIsDisabled(true);
			}

		} else {

			if (element.target.checked == true) {
				for (let x = 0; chackbox.length > x; x++) {
					if (document.querySelector(`#check${x}`).checked == false) {
						motherChackBox.checked = false;
						break;
					} else {
						motherChackBox.checked = true;
						setIsDisabled(false);
					}
				}
				setIsDisabled(false);
				array.push(item._id);
			} else {
				const index = array.findIndex((val) => val === item.id);
				array.splice(index, 1);
				motherChackBox.checked = false;
				for (let x = 0; chackbox.length > x; x++) {
					if (document.querySelector(`#check${x}`).checked == true) {
						setIsDisabled(false);
						break;
					} else {
						setIsDisabled(true);
					}
				}
			}
		}
		setCheckArray(array);
	};

	const onDownloadHandler = () => {
		dispatch(globalLoadingAction(true));
		dispatch(downloadEmployeeData(checkArray, props.history));
		setIsDisabled(true);
		setCheckArray([]);
		chackbox.forEach((val, index) => {
			chackbox[index].checked = false;
		});
		motherChackBox.checked = false;
	}

	const onKeyUpEmpIdHandler = (emp_id) => {
		verifyEmployeeId(emp_id.trim())
			.then((response) => {
				let errorObj = { ...errorsObj };
				errorObj.employeeId = response.data.status == 400 ? response.data.msg : '';
				setValidEmpid(response.data.status == 400 ? true : false);
				setErrors(errorObj);
			})
			.catch((error) => {
				let errorObj = { ...errorsObj };
				errorObj.employeeId = "";
				setValidEmpid(true);
				setErrors(errorObj);
				//console.log(error);
			});
	}

	const onKeyUpEmailHandler = (email) => {
		verifyEmail(email.trim())
			.then((response) => {
				let errorObj = { ...errorsObj };
				errorObj.email = response.data.status == 400 ? response.data.msg : '';
				setValidEmail(response.data.status == 400 ? true : false);
				setErrors(errorObj);
			})
			.catch((error) => {
				let errorObj = { ...errorsObj };
				errorObj.email = "";
				setValidEmail(true);
				setErrors(errorObj);
				//console.log(error);
			});
	}

	const onCleanHandler = () => {
		setSearchText('');
		setPageNo(1);
		setSort(null);
		setSordBy('');
		dispatch(globalLoadingAction(true));
		dispatch(getEmployeeList('', '', '', 1, perPage, props.history));
	}

	const handlePageClick = (data) => {
		setPageNo(data.selected + 1);
		dispatch(globalLoadingAction(true));
		dispatch(getEmployeeList(searchText, '', '', data.selected + 1, perPage, props.history));
		setSort(null);
		setSordBy('');
	};

	const onSearchHandler = (val) => {
		setSearchText(val);
		setSort(null);
		setSordBy('');
		const timerId = setTimeout(() => {
			dispatch(globalLoadingAction(true));
			dispatch(getEmployeeList(val, '', '', 1, perPage, props.history));
		}, 1000);
		return () => clearTimeout(timerId); // Cleanup on component unmount or re-render
	}

	const onSordByHandler = (val) => {
		setSordBy(val);
		setSort(null);
		//dispatch(globalLoadingAction(true));
		//dispatch(getEmployeeList(searchText, val, '', 1, perPage, props.history));
		//dispatch(getMsCustomerTypeData(search, 1, itemPerPage != "ALL" ? parseInt(itemPerPage) : 0, val, sort === null ? '' : sort ? 'asc' : 'desc', props.history));
	}

	const onSordHandler = () => {
		if (sordBy) {
			setSort(!sort);
			dispatch(globalLoadingAction(true));
			dispatch(getEmployeeList(searchText, sordBy, !sort ? 'asc' : 'desc', 1, perPage, props.history));
		}
	}

	const onLinkHandlerMain = (element, item) => {

		let dataArray = props.permissionsList;
		let array = [...quickLink];

		if (element.target.checked == true) {
			array.push(item.menu_id);
			for (let i = 0; dataArray.length > i; i++) {
				if (item.menu_id == dataArray[i].parent_id) {
					array.push(dataArray[i].menu_id);
					document.querySelector(`#link${dataArray[i]._id}`).checked = true;
					for (let x = 0; dataArray.length > x; x++) {
						if (dataArray[i].menu_id == dataArray[x].parent_id) {
							array.push(dataArray[x].menu_id);
							document.querySelector(`#link${dataArray[x]._id}`).checked = true;
						}
					}
				}
			}

		} else {
			const index = array.findIndex((val) => val === item.menu_id);
			array.splice(index, 1);

			for (let i = 0; dataArray.length > i; i++) {
				if (item.menu_id == dataArray[i].parent_id) {
					let childId = dataArray[i].menu_id;
					let removeId = array.includes(childId);
					if (removeId) {
						const parentIndex = array.findIndex((val) => val === dataArray[i].menu_id);
						array.splice(parentIndex, 1);
						document.querySelector(`#link${dataArray[i]._id}`).checked = false;
					}
					for (let x = 0; dataArray.length > x; x++) {
						if (dataArray[i].menu_id == dataArray[x].parent_id) {

							let lastChildId = dataArray[x].menu_id;
							let lastRemoveId = array.includes(lastChildId);
							if (lastRemoveId) {
								const childIndex = array.findIndex((val) => val === dataArray[x].menu_id);
								array.splice(childIndex, 1);
								document.querySelector(`#link${dataArray[x]._id}`).checked = false;
							}

						}
					}
				}
			}
		}
		setQuickLink(array);

	}

	const onLinkHandlerSub = (element, item, subItem) => {

		let dataArray = props.permissionsList;
		let array = [...quickLink];
		let parentId = array.includes(item.menu_id);

		if (element.target.checked == true) {

			if (parentId) {
				array.push(subItem.menu_id);
			} else {
				document.querySelector(`#link${item._id}`).checked = true;
				array.push(item.menu_id, subItem.menu_id);
			}
			for (let i = 0; dataArray.length > i; i++) {
				if (subItem.menu_id == dataArray[i].parent_id) {
					array.push(dataArray[i].menu_id);
					document.querySelector(`#link${dataArray[i]._id}`).checked = true;
				}
			}

		} else {

			const parentIndex = array.findIndex((val) => val === item.menu_id);
			const subIndex = array.findIndex((val) => val === subItem.menu_id);
			array.splice(subIndex, 1);
			let ins = false;

			for (let i = 0; dataArray.length > i; i++) {
				if (item.menu_id == dataArray[i].parent_id) {
					let removeId = array.includes(dataArray[i].menu_id);
					if (removeId) {
						ins = true;
						break
					}

				}
			}

			if (!ins) {
				array.splice(parentIndex, 1);
				document.querySelector(`#link${item._id}`).checked = false;
			}

			for (let i = 0; dataArray.length > i; i++) {
				if (subItem.menu_id == dataArray[i].parent_id) {
					let removeChildId = array.includes(dataArray[i].menu_id);
					if (removeChildId) {
						const childIndex = array.findIndex((val) => val === dataArray[i].menu_id);
						array.splice(childIndex, 1);
						document.querySelector(`#link${dataArray[i]._id}`).checked = false;
					}

				}
			}
		}

		setQuickLink(array);
	}

	const onLinkHandlerUnderSub = (element, item, subItem, underSubItem) => {

		let dataArray = props.permissionsList;
		let array = [...quickLink];
		let parentId = array.includes(item.menu_id);
		let secondParentId = array.includes(subItem.menu_id);

		if (element.target.checked == true) {

			if (parentId && secondParentId) {
				array.push(underSubItem.menu_id);
			} else if (parentId) {
				document.querySelector(`#link${subItem._id}`).checked = true;
				array.push(subItem.menu_id, underSubItem.menu_id);

			} else {
				document.querySelector(`#link${item._id}`).checked = true;
				document.querySelector(`#link${subItem._id}`).checked = true;
				array.push(item.menu_id, subItem.menu_id, underSubItem.menu_id);
			}

		} else {

			const parentIndex = array.findIndex((val) => val === item.menu_id);
			const childIndex = array.findIndex((val) => val === subItem.menu_id);
			const lastMenuIndex = array.findIndex((val) => val === underSubItem.menu_id);
			array.splice(lastMenuIndex, 1);

			let isLastChild = false;
			let isSubChild = false;

			for (let i = 0; dataArray.length > i; i++) {
				if (subItem.menu_id == dataArray[i].parent_id) {
					let removeId = array.includes(dataArray[i].menu_id);
					if (removeId) {
						isLastChild = true;
						break
					}
				}
			}

			if (!isLastChild) {

				array.splice(childIndex, 1);
				document.querySelector(`#link${subItem._id}`).checked = false;

				for (let i = 0; dataArray.length > i; i++) {
					if (item.menu_id == dataArray[i].parent_id) {
						let removeSubId = array.includes(dataArray[i].menu_id);
						if (removeSubId) {
							isSubChild = true;
							break
						}
					}
				}

				if (!isSubChild) {
					array.splice(parentIndex, 1);
					document.querySelector(`#link${item._id}`).checked = false;
				}
			}
		}

		setQuickLink(array);

	}

	const onOuickLinkStatus = (id) => {
		let status = null;
		const index = permission.findIndex((val) => val === id);
		if (index >= 0) {
			status = false;
		} else {
			status = true;
		}
		return false;
	}

	const onCurrentStatus = (id) => {
		let status = null;
		const index = defaultPermission.findIndex((val) => val === id);
		if (index >= 0) {
			status = true;
		} else {
			status = false;
		}
		return status;
	}

	return (
		<>
			<div className="row">
				<div className="breadcrumb-container">
					<ul>
						<li>
							<Link to={"#"}>System Setting</Link>
						</li>
						<li><i className="fa fa-angle-right"></i></li>
						<li>
							<Link to={"#"}>Manage Users</Link>
						</li>
					</ul>
				</div>
				<div className="title-body-container">
					<div className="title-body">
						<h3>Manage Users</h3>
						<p>Maintain user information - Add, edit, delete and invite users</p>
					</div>
					<div className="title-filter-container">
						<div className="search-filter">
							<input placeholder="Search" value={searchText} onChange={(e) => onSearchHandler(e.target.value)} />
							<i class="fa fa-search" aria-hidden="true"></i>
						</div>

						<div className="sort-filter">
							<select
								defaultValue={"option"}
								className=""
								value={sordBy}
								onChange={(e) => onSordByHandler(e.target.value)}>
								<option>Sort by</option>
								<option value="first_name">Name</option>
								<option value="email">Email</option>
								<option value="phone_no">Mobile</option>
								{/* <option value="user type">User type</option> */}
							</select>
							<button className={sort === null ? null : sort ? "sorting-asc" : "sorting-dec"} onClick={onSordHandler}>
								<i class="fa fa-long-arrow-up" aria-hidden="true"></i>
								<i class="fa fa-long-arrow-down" aria-hidden="true"></i>
							</button>
						</div>

						<div className="button-filter">
							<button className="add-user-btn" onClick={() => onAddHandler()}><i class="fa fa-plus" aria-hidden="true"></i></button>
							<button className="add-filter-up-btn"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>
						</div>

					</div>

				</div>
				<div className="account-setting-content">
					<Col lg={12}>
						<Card>
							<Card.Header>
								<div className="add-role-button-view">
									<div className="add-btn-group">
										<button disabled={isDisabled}><i class="fa fa-external-link" aria-hidden="true"></i></button>
										<button
											onClick={() => onMultipleRemoveHandler()}
											disabled={isDisabled}>X</button>
										<button disabled={isDisabled}>S</button>
										<button
											onClick={() => onDownloadHandler()}
											disabled={isDisabled}>
											<i class="fa fa-download" aria-hidden="true"></i></button>
									</div>
								</div>
							</Card.Header>
							{console.log('props.employeeList...',props.employeeList)}
							<Card.Body>
								{props.screenVisible ?
									<>
										{props.employeeList.length > 0 ?
											<Table responsive>
												<thead>
													<tr>
														<th className="customer_shop">
															<div className="form-check custom-checkbox mx-2">
																<input
																	type="checkbox"
																	className="form-check-input"
																	id="checkAll"
																	onClick={(e) => chackboxFun('all', 0)}
																/>
																<label
																	className="form-check-label"
																	htmlFor="checkAll"
																></label>
															</div>
														</th>
														<th>
															<strong>ID</strong>
														</th>
														<th>
															<strong>Name</strong>
														</th>
														<th>
															<strong>Email</strong>
														</th>
														<th>
															<strong>Role</strong>
														</th>
														<th>
															<strong>User type</strong>
														</th>
														<th>
															<strong>Date</strong>
														</th>
														<th>
															<strong>Status</strong>
														</th>
														<th>
															<strong>Actions</strong>
														</th>
													</tr>
												</thead>
												<tbody>
													{
														props.employeeList.map((item, index) => {
															return (
																<tr key={index}>
																	<td>
																		<div className="form-check custom-checkbox checkbox-select mx-2">
																			<input
																				type="checkbox"
																				className="form-check-input"
																				id={`check${index}`}
																				onClick={(e) => chackboxFun(e, item)}
																			/>
																			<label
																				className="form-check-label"
																				htmlFor={`check${index}`}
																			></label>
																		</div>
																	</td>
																	<td>{item.other_details[0]?.employee_id}</td>
																	<td>{item.first_name + " " + item.last_name}</td>
																	<td>{item.email}</td>
																	<td>{item.role_name}</td>
																	<td>{item.other_details[0]?.user_type_name}</td>
																	<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
																	<td>
																		{(() => {
																			switch (parseInt(item.status)) {
																				case 0:
																					return <Button className="btn-xs" variant="danger"
																						onClick={() => onStatusHandler(item)}>
																						Inactive</Button>
																				case 1:
																					return <Button className="btn-xs" variant="success"
																						onClick={() => onStatusHandler(item)}>
																						Active</Button>
																				case 2:
																					return <Button className="btn-xs" variant="warning">
																						Pending</Button>
																				default:
																					return null
																			}
																		})()}
																	</td>
																	<td>
																		<div className="d-flex">

																			<Link
																				onClick={() => onEditHandler(item)}
																				className="btn btn-warning shadow btn-xs sharp me-1"
																			>
																				<i className="fas fa-pencil-alt"></i>
																			</Link>

																			<Button
																				className="btn btn-danger shadow btn-xs sharp me-1"
																				onClick={() => onDeleteHandler(item._id)}
																			>
																				<i className="fa fa-trash"></i>
																			</Button>

																			<Link
																				//onClick={() => onEditHandler(item)}
																				className="btn btn-info shadow btn-xs sharp"
																			>
																				<i className="fa fa-external-link-square"></i>
																			</Link>
																		</div>
																	</td>
																</tr>
															);
														})
													}
												</tbody>
											</Table>
											:
											<div className="nodata-found-view">
												<i class="fa fa-search" aria-hidden="true"></i>
												<h2>Data not found</h2>
												<p>Customer type data is empty or Try again search</p>
												<button type="button" onClick={onCleanHandler}>
													Retry
												</button>
											</div>
										}
									</>
									: null}
								<div className="pagination-container">
									{parseInt(props.totalRecord) > 0 && props.showLoading == false ? (
										<div className="page-result alignResult">
											<p>Showing{" "}{(pageNo - 1) * perPage + 1 + " - " + ((pageNo - 1) * perPage + props.employeeList.length) + " of " + "( " + props.totalRecord + " items )"}</p>
										</div>
									) : (
										<div></div>
									)}

									{parseInt(props.totalRecord) > 10 ? (
										<ReactPaginate
											containerClassName="pagination-gutter false pagination-circle pagination"
											pageClassName="page-item"
											pageLinkClassName="page-link"
											breakLabel="..."
											nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
											nextClassName="page-item"
											nextLinkClassName="page-link"
											previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
											previousClassName="page-item"
											previousLinkClassName="page-link"
											breakClassName="page-item"
											breakLinkClassName="page-link"
											renderOnZeroPageCount={null}
											activeClassName="active"
											onPageChange={handlePageClick}
											pageRangeDisplayed={3}
											pageCount={props.totalPage}
											//initialPage={active - 1}
											forcePage={pageNo - 1}
										/>
									) : null}
								</div>
							</Card.Body>
						</Card>
					</Col>
				</div>

				<Modal
					className="fade bd-example-modal-sm"
					size="lg"
					show={addModal}
					onHide={() => setAddModal(false)}
				>
					<div className="add-user-modal-form-view client-modal">
						<button onClick={() => setAddModal(false)} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
						<div className="modal-title-view">
							<h3>{action == 1 ? "Add User" : "Update User"}</h3>
							<p>Manage Company/Organization Information such as name, type, location address and contacts.
							</p>
						</div>
						<div className="basic-form">
							<form onSubmit={onSubmit}>

								<div className="row">
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											First Name
										</label>
										<input className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
										{errors.fName && <div className="text-danger fs-12">{errors.fName}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Last Name
										</label>
										<input className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)} />
										{errors.lName && <div className="text-danger fs-12">{errors.lName}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Email
										</label>
										<input className="form-control"
											value={email}
											readOnly={action == 2 ? true : false}
											onKeyUp={(e) => onKeyUpEmailHandler(e.target.value)}
											onChange={(e) => setEmail(e.target.value)} />
										{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Mobile
										</label>
										<input className="form-control" value={mobile} onChange={(e) => setMobile(e.target.value)} />
										{errors.mobile && <div className="text-danger fs-12">{errors.mobile}</div>}
									</div>
									{action == 1 ?
										<div className="form-group form-group-modal-custom mb-3 col-6">
											<label className="mb-1">
												Employee Id
											</label>
											<input className="form-control" value={employeeId}
												readOnly={action == 2 ? true : false}
												onKeyUp={(e) => onKeyUpEmpIdHandler(e.target.value)} onChange={(e) => setEmployeeId(e.target.value)} />
											{errors.employeeId && <div className="text-danger fs-12">{errors.employeeId}</div>}
										</div>
										: null}


									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											User Type
										</label>
										<div className="select-drop-down">
											<span><i class="fa fa-angle-down"></i></span>
											<select
												defaultValue={"option"}
												class="form-control select-control"
												value={userType}
												onChange={(e) => setUserType(e.target.value)}>
												<option></option>
												<option value="employee(INT)">Employee</option>
												<option value="contructor(EXT)">Contructor</option>
												<option value="volunteer(EXT)">Volunteer</option>
											</select>
										</div>
										{errors.userType && <div className="text-danger fs-12">{errors.userType}</div>}
									</div>

									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Rate (per hour)
										</label>
										<input className="form-control" type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
										{errors.rate && <div className="text-danger fs-12">{errors.rate}</div>}
									</div>

									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Regular Hour
										</label>
										<input className="form-control" type="number" value={hours} onChange={(e) => setHours(e.target.value)} />
										{errors.hours && <div className="text-danger fs-12">{errors.hours}</div>}
									</div>

									{action == 2 ?
										<div className="form-group form-group-modal-custom mb-3 col-6">
											<label className="mb-1">
												Status
											</label>
											<div className="select-drop-down">
												<span><i class="fa fa-angle-down"></i></span>
												<select
													defaultValue={"option"}
													class="form-control select-control"
													value={status}
													onChange={(e) => setStatus(e.target.value)}>
													<option></option>
													<option value="1">Active</option>
													<option value="0">Inactive</option>
													<option value="2">Pending</option>
												</select>
											</div>
											{errors.status && <div className="text-danger fs-12">{errors.status}</div>}
										</div>
										: null}

									<div className="account-container">

										<div
											className={accOne ? 'account-item account-item-active' : 'account-item'}
										>
											<div
												className={accOne ? 'account-button account-active' : 'account-button'}
												onClick={onCollapseHandler}
											><h3>Security</h3><span>
													{accOne ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-right" aria-hidden="true"></i>}
												</span></div>
											<div className={accOne ? 'account-collapse show' : 'account-collapse'}>
												<div className="row">
													{action == 1 ?
														<>
															<div className="form-group form-group-modal-custom mb-3 col-6">
																<label className="mb-1">
																	Password
																</label>
																<div className="form-group-pws">
																	<input type={pws ? 'text' : 'password'} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
																	<div className="pws-icon-view" onClick={() => setPws(!pws)}>
																		{pws ?
																			<i class="bi bi-eye"></i>
																			:
																			<i class="bi bi-eye-slash"></i>
																		}
																	</div>
																</div>
																{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
															</div>

															<div className="form-group form-group-modal-custom mb-3 col-6">
																<label className="mb-1">
																	Confirm Password
																</label>
																<div className="form-group-pws">
																	<input type={conPws ? 'text' : 'password'} className="form-control" value={conPassword} onChange={(e) => setConPassword(e.target.value)} />
																	<div className="pws-icon-view" onClick={() => setConPws(!conPws)}>
																		{conPws ?
																			<i class="bi bi-eye"></i>
																			:
																			<i class="bi bi-eye-slash"></i>
																		}
																	</div>
																</div>
																{errors.conPassword && <div className="text-danger fs-12">{errors.conPassword}</div>}
															</div>
														</>
														: null}

													<div className="form-group form-group-modal-custom mb-3 col-6">
														<label className="mb-1">
															Role name
														</label>
														<div className="select-drop-down">
															{props.roleLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																:
																<span><i class="fa fa-angle-down"></i></span>
															}
															<select
																defaultValue={"option"}
																class="form-control select-control"
																value={roleName}
																onChange={(e) => setRoleName(e.target.value)}>
																<option></option>
																{
																	props.activeRole.map((item, index) => {
																		return <option key={index} value={item._id + '^' + item.is_default}>{item.role_name}</option>
																	})
																}
															</select>
														</div>
														{errors.role && <div className="text-danger fs-12">{errors.role}</div>}
													</div>
													<div className="form-group mt-5 col-6">
														<div className="form-check">
															<input
																type="checkbox"
																className="form-check-input"
																id="supervisor-check"
																checked={isSupervisor}
															    onClick={(e) => setIsSupervisor(e.target.checked)}
															/>
															<label
																className="form-check-label"
																htmlFor="supervisor-check"
															>Supervisor</label>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div
											className={accTwo ? 'account-item account-item-active' : 'account-item'}
										>
											<div
												className={accTwo ? 'account-button account-active' : 'account-button'}
												onClick={onCollapseHandler2}
											><h3>User options</h3><span>
													{accTwo ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-right" aria-hidden="true"></i>}
												</span></div>
											<div className={accTwo ? 'account-collapse show' : 'account-collapse'}>

												<div className="row">
													<div className="form-group form-group-modal-custom mb-3 col-6">
														<label className="mb-1">
															Group
														</label>
														<div className="select-drop-down">
															{props.teamLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																:
																<span><i class="fa fa-angle-down"></i></span>
															}
															<select
																defaultValue={"option"}
																class="form-control select-control"
																value={groupName}
																onChange={(e) => setGroupName(e.target.value)}>
																<option></option>
																{
																	props.teamList.map((item, index) => {
																		return <option key={index} value={item._id}>{item.groupId}</option>
																	})
																}
															</select>
														</div>
														{errors.group && <div className="text-danger fs-12">{errors.group}</div>}
													</div>

													{roleName.split('^')[0] == "64347e34002f851734f768cf" ?
														<>
															<div className="form-group form-group-modal-custom mb-3 col-6">
																<label className="mb-1">
																	Time Approver
																</label>
																<div className="select-drop-down">
																	{props.showLoading && loadingStatus == 1 ?
																		<Spinner
																			as="span"
																			animation="border"
																			size="sm"
																			role="status"
																			aria-hidden="true"
																		/>
																		:
																		<span><i class="fa fa-angle-down"></i></span>
																	}
																	<select
																		defaultValue={"option"}
																		class="form-control select-control"
																		value={approver}
																		onClick={getApproverHandler}
																		onChange={(e) => setApprover(e.target.value)}>
																		<option>Select Approver name</option>
																		{
																			props.approverList.map((item, index) => {
																				return <option key={index} disabled={groupName == item.other_details[0]?.group_id ? false : true} value={item._id}>{item.first_name} {item.last_name}</option>
																			})
																		}
																	</select>
																</div>
																{errors.approver && <div className="text-danger fs-12">{errors.approver}</div>}
															</div>
															<div className="form-group form-group-modal-custom mb-3 col-6">
																<label className="mb-1">
																	Time Reviewer
																</label>
																<div className="select-drop-down">
																	{props.showLoading && loadingStatus == 2 ?
																		<Spinner
																			as="span"
																			animation="border"
																			size="sm"
																			role="status"
																			aria-hidden="true"
																		/>
																		:
																		<span><i class="fa fa-angle-down"></i></span>
																	}
																	<select
																		defaultValue={"option"}
																		class="form-control select-control"
																		value={reviewer}
																		onClick={getReviewerHandler}
																		onChange={(e) => setReviewer(e.target.value)}>
																		<option>Select reviewer name</option>
																		{
																			props.reviewerList.map((item, index) => {
																				return <option key={index} disabled={groupName == item.other_details[0]?.group_id ? false : true} value={item._id}>{item.first_name} {item.last_name}</option>
																			})
																		}
																	</select>
																</div>
																{errors.reviewer && <div className="text-danger fs-12">{errors.reviewer}</div>}
															</div>
														</>
														: null}

												</div>

											</div>
										</div>

										{/* <div
											className={accThree ? 'account-item account-item-active' : 'account-item'}
										>
											<div
												className={accThree ? 'account-button account-active' : 'account-button'}
												onClick={onCollapseHandler3}
											><h3>Previlege</h3><span>
													{accThree ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-right" aria-hidden="true"></i>}
												</span></div>
											<div className={accThree ? 'account-collapse show' : 'account-collapse'}>

												<div className="row">

													<div className="role-menu-container">
														{
															props.permissionsList.map((item, index) => {
																if (item.parent_id == 0) {
																	return (
																		<>
																			{item.menu_id == 9 ? null :
																				<div className="role-menu-item">
																					<div className="form-group mt-3">
																						<div key={index} className="form-check">
																							<label className="form-check-label">
																								<input
																									type="checkbox"
																									className="form-check-input"
																									id={`link${item._id}`}
																									defaultChecked={onCurrentStatus(item.menu_id)}
																									disabled={onOuickLinkStatus(item.menu_id)}
																									onClick={(e) => onLinkHandlerMain(e, item)}
																								/>
																								{item.title}
																							</label>
																						</div>
																					</div>
																					{
																						props.permissionsList.map((data, i) => {
																							if (data.parent_id == item.menu_id) {
																								return (
																									<div className="form-group form-check-inline sub-checkMark">
																										<div key={index} className="form-check">
																											<label className="form-check-label">
																												<input
																													type="checkbox"
																													className="form-check-input"
																													id={`link${data._id}`}
																													defaultChecked={onCurrentStatus(data.menu_id)}
																													disabled={onOuickLinkStatus(data.menu_id)}
																													onClick={(e) => onLinkHandlerSub(e, item, data)}
																												/>
																												{data.title}
																											</label>
																										</div>

																										{
																											props.permissionsList.map((subData, j) => {
																												if (subData.parent_id == data.menu_id) {
																													return (

																														<label className="form-check-label sub-checkMark">
																															<input
																																type="checkbox"
																																className="form-check-input"
																																id={`link${subData._id}`}
																																defaultChecked={onCurrentStatus(subData.menu_id)}
																																onClick={(e) => onLinkHandlerUnderSub(e, item, data, subData)}
																															/>
																															{subData.title}
																														</label>

																													)
																												}
																											})
																										}

																									</div>
																								)
																							}
																						})
																					}



																				</div>
																			}
																		</>
																	);
																}
															})
														}

													</div>

												</div>

											</div>
										</div> */}

									</div>

								</div>
								<div className="modal-button-group mt-4">
									<button type="button" onClick={() => setAddModal(false)} className="btn form-cencel-btn">
										Cancel
									</button>
									<button type="submit" className="btn btn-primary role-form-btn">
										{action == 1 ? "Save" : "Update"}
									</button>
								</div>
							</form>
						</div>

					</div>
				</Modal>

			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		screenVisible: state.employee.screenVisible,
		showLoading: state.employee.showLoading,
		roleLoading: state.role.showLoading,
		activeRole: state.role.activerRoleList,
		employeeList: state.employee.employeeList,
		totalPage: state.employee.totalPage,
		totalRecord: state.employee.totalRecord,
		companyId: state.auth.companyId,
		approverList: state.employee.approverList,
		reviewerList: state.employee.reviewerList,
		teamLoading: state.team.showLoading,
		teamList: state.team.teamList,
		countryList: state.country.countryList,
		permissionsList: state.role.permissionsList,
		previledgeList: state.role.previledgeList,
	};
};
export default connect(mapStateToProps)(ManageUsers);